<template>
    <div class="floor">
        <p>本站所有图片均来源于必应搜索</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.floor{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    background: rgb(255, 255, 255);
    color:rgb(80, 80, 80);
}
.floor p{
    font-size: 16px;
}
</style>