<template>
  <div ref="app" id="app">
    <nav-vue></nav-vue>
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import NavVue from './components/Nav.vue'
export default {
  name: 'App',
  components:{
    NavVue
  },
  methods:{
    showhm(){
      this.$bus.$on('show',(val)=>{
          this.$nextTick(()=>{
            if(val){
              this.$refs.app.style.height = "100vh"
            }else{
              this.$refs.app.style.height = "auto"
            }
          })
        })
      }
  },
  mounted(){
    //这个函数放到home上使用就会报错，页面加载无法获取dom
    this.showhm()
  }
}
</script>

<style>
*{
  padding:0;
  margin:0;
  box-sizing: border-box;
}
#app{
  position: relative;
  overflow: hidden;
}
</style>
