import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './http'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false;
Vue.prototype.http = http

const  loadimage = require('./assets/loading.png')

Vue.use(VueLazyload,{
  loading: loadimage
})

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  },
}).$mount('#app')
