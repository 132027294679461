<template>
  <div ref="nav" class="nav">
    <div ref="san" @click="showOn" class="san"></div>
    <div ref="con" @click.self="showOff" class="con">
        <div class="tagList">
            <h2>Bing Picture</h2>
            <ul>
                <li>
                    <h3 @click="showlist" style="padding: 7px 0;display: flex;align-items: center;justify-content: space-between;" >
                        <span>归档</span>
                        <span ref="triangle"></span>
                    </h3>
                    <div ref="gdlist">
                        <div @click="selectGet('qb')" style="padding: 4px 10px;">全部</div>
                        <div @click="selectGet(item.name)" v-for="item in categoryArr" :key="item._id" style="padding: 4px 10px;">{{ item.name }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            categoryArr:[]
        }
    },
    methods:{
        showOn(){
            this.$refs.san.style.display="none"
            this.$refs.nav.style.width="100%"
            this.$refs.con.style.display="block"
            this.$bus.$emit('show',true)
        },
        showOff(){
            this.$refs.san.style.display="block"
            this.$refs.nav.style.width="auto"
            this.$refs.con.style.display="none"
            this.$bus.$emit('show',false)
        },
        showlist(){
            if(this.$refs.gdlist.style.display==="block"){
                this.$refs.gdlist.style.display="none"
                this.$refs.triangle.style.backgroundColor="rgb(56, 56, 56)"
            }else{
                this.$refs.gdlist.style.display="block"
                this.$refs.triangle.style.backgroundColor="rgb(0, 162, 255)"
            }
        },
        selectGet(index){
            this.showlist()
            this.showOff()
            this.$router.push({name:'Home',params:{date:index,current:1}}).catch(err=>err)
        },
        async fetch(){
           let res = await this.http.get('/categoryDate')
           this.categoryArr = res.data
        }
    },
    mounted(){
        this.fetch()
    }
}
</script>

<style>
.nav{
    position: relative;
    right:0;
    top:0;
    z-index: 10;
}
.nav .san{
    top:0;
    right: 0;
    position: absolute;
    width: 60px;
    height: 60px;
    background: rgb(255, 255, 255);
    clip-path:polygon(0 0, 100% 0, 100% 100%,0 0);
    box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,.5);
    display: block;
}
.nav .con{
    /* position: fixed; */
    position: absolute;
    top:0;
    right: 0;
    display: none;
    width:100%;
    height: 100vh;
    background: rgba(0,0,0,.6);
}
.nav .con .tagList{
    /* 直接占满视图的100%，无视滚动条 */
    position: fixed;
    top:0;
    right: 0;
    padding: 35px 30px;
    width:300px;
    height: 100%;
    background-color: #fafafa;
    overflow-y: scroll;
}
.nav .con .tagList>h2{
    text-align: center;
    font-weight: 500;
}
.nav .con .tagList>ul{
    list-style: none;
    margin-top:20px;
}
.nav .con .tagList>ul li{
    border-bottom:1px solid rgba(0,0,0,0.25);
}
.nav .con .tagList>ul li>h3>span:nth-of-type(2){
    display: block;
    height: 12px;
    width:12px;
    background-color: rgb(56, 56, 56);
    transform-origin: center;
    transform: rotate(135deg);
    margin: 0 0 4px 4px;
    clip-path:polygon(0 0, 100% 0, 100% 100%,0 0);
}
.nav .con .tagList>ul li>h3:hover span:nth-of-type(2){
    background-color: rgb(0, 162, 255);
}
.nav .con .tagList>ul li:nth-of-type(1)>div{
    display: none;
    margin-top: 10px;
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px 0;
    box-shadow: inset 0px 8px 10px -10px rgba(0,0,0,0.1);
}
.nav .con .tagList>ul li:nth-of-type(1)>div>div:hover{
    background-color: rgb(56, 182, 255);
}
</style>