import Home from '../views/Home.vue'

export default [
    {
        name:"Home",
        path:"/Home/:date/:current",
        component:Home,
        props:true
        //这里不能用来重定向页面，参数无法像params在path上表达
        // props(route){
        //     return{
        //         date:route.query.date,
        //         current:route.query.current
        //     }
        // }
    },
    {
        path:"/",
        redirect:"/Home/qb/1"
    }
]