<template>
    <div>
        <div class="pagination">
            <div v-show="pagModel.current>1" @click="currentChange('-')">上一页</div>
            <div v-for="item in ShowPageSize" :key="item" 
            :style="{'color':item==pagModel.current?'#0671F9':'black','border':item==pagModel.current?'1px solid #0671F9':'1px solid rgba(0, 0, 0, .07)'}"
            @click="currentChange(item)" 
            class="current">
                {{item}}
            </div>
            <div v-show="parseInt(pagModel.current)<count" @click="currentChange('+')">下一页</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['pagModel'],
    data() {
        return {}
    },
    computed:{
        ShowPageSize(){
            let total = parseInt(this.pagModel.total)
            let pageSize = parseInt(this.pagModel.pageSize)
            let count = Math.ceil(total/pageSize)
            let current = parseInt(this.pagModel.current)
            if(count<4){
                return Array.from({length:count},(x,i)=>i+1)
            }else if(current==1){
                return Array.from({length:3},(x,i)=>i+current)
            }else if(current>1 && current<count){
                return [current-1,current,current+1]
            }else{
                return [count-2,count-1,count]
            }
        },
        count(){
            let total = parseInt(this.pagModel.total)
            let pageSize = parseInt(this.pagModel.pageSize)
            return Math.ceil(total/pageSize)
        }
    },
    methods:{
        currentChange(contore){
            if(contore==='+'){
                let total = parseInt(this.pagModel.total)
                let pageSize = parseInt(this.pagModel.pageSize)
                this.pagModel.current<Math.ceil(total/pageSize)?this.pagModel.current++:this.pagModel.current=Math.ceil(total/pageSize)
                //由于刷新会回到首页，所以这里添加了页码参数到了路由
                // this.$router.push({path:'Home',parems:{}})这种写法为什么官方不使用，
                //个人认为与this.$router.push({path:`/Home/${}`})冲突，都是用的path
                this.$router.push({name:'Home',params:{date:this.pagModel.date,current:this.pagModel.current}}).catch(err=>err)
            }else if(contore==='-'){
                // console.log(this.pagModel.current)
                this.pagModel.current>1?this.pagModel.current--:this.pagModel.current=1
                this.$router.push({name:'Home',params:{date:this.pagModel.date,current:this.pagModel.current}}).catch(err=>err)
            }else{
                this.$router.push({name:'Home',params:{date:this.pagModel.date,current:parseInt(contore)}}).catch(err=>err)
            }
            // this.$emit("pagination",this.pagModel)
        },
    }
}
</script>

<style scoped>
.pagination{
    display: flex;
    justify-content: center;
    margin:10px 0 40px 0;
    color:rgb(99, 99, 99);
}
.pagination div{
    padding:9px 14px;
    background: white;
    margin: 0 3px;
    border:1px solid rgba(0, 0, 0, .07);
    min-width:40px;
    text-align: center;
    font-size: 12px;
}
.pagination .current{
    padding:9px 0px;
    max-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
</style>