<template>
  <div ref="home" class="home">
    <div class="dayPic">
      <div class="pic" 
      v-if="firstImg.ImageContent" 
      :style="{'background':'no-repeat center/cover url('+firstImg.ImageContent.Image.k1+')'}">
      </div>
      <div class="text">
        <h1>Bing Picture</h1>
        <p v-if="firstImg.ImageContent" style="letter-spacing: 3px;text-align: center;padding: 0 20px;">
          {{firstImg.ImageContent.Title+firstImg.ImageContent.Copyright}}
        </p>
      </div>
    </div>
    <div class="content">
      <div class="cont_one" v-for="item in imgUrlArr" :key="item._id">
        <div class="cont_one_img">
          <img width="100%" v-lazy="item.ImageContent.Image.PreviewImgUrl">
          <div class="img_description">
            <p>{{item.ImageContent.Title}}</p>
          </div>
        </div>
        <div class="cont_one_date">
          <p>{{item.FullDateString}}</p>
          <a target="_blank" :href="item.ImageContent.Image.k2">picture-4k</a>
        </div>
      </div>
    </div>
    <!-- 这个分页还是不能用@pagination="getPic" -->
    <pagination-vue :pagModel="paginationModel"></pagination-vue>
    <floor-vue></floor-vue>
  </div>
</template>

<script>
import FloorVue from '../components/Floor.vue'
import PaginationVue from '../components/Pagination.vue'

export default {
    data() {
        return {
            firstImg:{},
            imgUrlArr:[{
              FullDateString:"",
              Description:"",
              ImageContent:{
                Image:{
                  k1:"",
                  k2:"",
                  PreviewImgUrl:""
                }
              }
            }],
            paginationModel:{
              //目前所在日期分类
              date:'qb',
              //目前所在页
              current:1,
              //页面数据数量
              pageSize:15,
              //数据总数
              total:0
            }
        }
    },
    props:['current','date'],
    components:{
      PaginationVue,
      FloorVue
    },
    methods:{
      //组件的使用类似于函数，输入参数，调用得出结果，刷新请求参数，进而重新发出请求，可以参考别人封装好的ui框架
      //这个getPic就相当于调用组件功能了
      // getPic(e){
      //   this.paginationModel = e
      // },
      async fetch(){
        // console.log(this.$route.query)
        // console.log(this.current)
        this.paginationModel.current = this.current
        this.paginationModel.date = this.date
        let res = await this.http.get('/getPic')
        let res_obj = await this.http.get('/currentPic',{params:this.paginationModel})
        this.firstImg = res.data
        this.imgUrlArr = res_obj.data.urlArr
        this.paginationModel = res_obj.data.paginationModel
        // console.log(res.data)
      }
    },
    mounted(){
      this.fetch()
    }
}
</script>

<style scoped>
.home{
  overflow: hidden;
  height: auto;
  background-color: whitesmoke;
}
.dayPic{
  width:100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.pic{
  width:100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}
.text{
  width:100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text h1{
  color:white;
  font-size: 6.5vmin;
  font-weight: 500;
  margin-bottom: 30px;
}
.text p{
  color:whitesmoke;
}
.content{
  width: 100%;
  overflow: hidden;
  padding:40px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
.content .cont_one{
  width: 100%;
}
.cont_one .cont_one_img{
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.cont_one_img img{
  display: block;
  position: relative;
  z-index: -1;
}
.cont_one_img .img_description{
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  z-index: 1;
  opacity: 0;
  transition-duration: .3s;
}
.img_description p{
  margin: 10px 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.cont_one_img:hover .img_description{
  opacity: 1;
}
.cont_one .cont_one_date{
  margin-top:10px;
  display: flex;
  justify-content: center;
}
.cont_one .cont_one_date a{
  color: #000;
  margin-left:10px;
  text-decoration:underline;
}
@media screen and (max-width:600px){
  .content{
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
    padding:20px !important;
    display: flex !important;
    flex-wrap: wrap;
  }
}
@media screen and (max-width:980px){
  .content{
    width: 100%;
    overflow: hidden;
    padding:30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
}
@media screen and (min-width:1200px){
  .content{
    width: 1200px !important;
    margin:auto;
    overflow: hidden;
  }
}
</style>